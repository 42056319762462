import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import GamesPage from '../views/GamesPage.vue';
import AboutPage from '../views/AboutPage.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/', // Default route
        name: 'IndexPage',
        component: HomePage,
      },
      {
        path: '/home', // Route for /home
        name: 'HomePage',
        component: HomePage,
      },
      {
        path: '/games', // Route for /home
        name: 'GamesPage',
        component: GamesPage,
      },
      {
        path: '/about', // Route for /home
        name: 'AboutPage',
        component: AboutPage,
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(), // Use history mode
  routes,
});

export default router;
