<template>
    <main :class="{ 'modal-open': activePopup }" :style="activePopup ? 'overflow: hidden; padding-right: 6px;' : ''">
        <LoginPopup v-if="activePopup === 'login'" />
        <div>
            <HeaderComponent />
            <div>
                <router-view />
            </div>
            <FooterComponent />
        </div>
    </main>
</template>

<script>
import { computed } from 'vue';
import { usePopupStore } from '../stores/popup';
import HeaderComponent from '../components/HeaderComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import LoginPopup from '../components/LoginPopup.vue';

export default {
    name: 'DefaultLayout',
    components: {
        HeaderComponent,
        FooterComponent,
        LoginPopup
    },
    setup() {
        // Access the popup store
        const popupStore = usePopupStore();

        // Computed properties for activePopup
        const activePopup = computed(() => popupStore.activePopup);

        // Methods to show and hide the popup
        const showPopup = popupStore.showPopup;
        const hidePopup = popupStore.hidePopup;

        return {
            activePopup,
            showPopup,
            hidePopup,
        };
    },
};
</script>