<template>
  <div class="log-reg">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="modal fade" id="loginMod">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header justify-content-center">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <ul class="nav log-reg-btn d-none justify-content-around">
                  <li class="bottom-area" role="presentation">
                    <button class="nav-link" id="regArea-tab" data-bs-toggle="tab" data-bs-target="#regArea"
                      type="button" role="tab" aria-controls="regArea" aria-selected="false">
                      SIGN UP
                    </button>
                  </li>
                  <li class="bottom-area" role="presentation">
                    <button class="nav-link active" id="loginArea-tab" data-bs-toggle="tab" data-bs-target="#loginArea"
                      type="button" role="tab" aria-controls="loginArea" aria-selected="true">
                      LOGIN
                    </button>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade show active" id="loginArea" role="tabpanel" aria-labelledby="loginArea-tab">
                    <div class="login-reg-content">
                      <div class="modal-body">
                        <div class="form-area">
                          <form action="#">
                            <div class="section-text text-center">
                              <h3 class="title">Welcome Back</h3>
                              <p>Not a member ? <a href="javascript:void(0)" class="reg-btn">Register</a></p>
                            </div>
                            <div class="input-area d-flex align-items-center">
                              <img src="assets/images/icon/email-icon.png" alt="icon">
                              <input type="text" placeholder="Email">
                            </div>
                            <div class="input-area d-flex align-items-center">
                              <img src="assets/images/icon/security.png" alt="icon">
                              <input type="text" placeholder="Password">
                            </div>
                            <div class="checkbox-item d-flex justify-content-between">
                              <label class="checkbox-single d-flex align-items-center">
                                <span class="content-area">
                                  <span class="checkbox-area d-flex">
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                  </span>
                                  <span class="item-title d-flex align-items-center">
                                    <span>Remember me</span>
                                  </span>
                                </span>
                              </label>
                              <a href="javascript:void(0)">Forget Password</a>
                            </div>
                            <div class="btn-area text-center">
                              <a href="javascript:void(0)" class="cmn-btn mt-4">Login
                                <i class="icon-d-right-arrow-2"></i>
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="regArea" role="tabpanel" aria-labelledby="regArea-tab">
                    <div class="login-reg-content regMode">
                      <div class="modal-body">
                        <div class="form-area">
                          <form action="#">
                            <div class="section-text text-center">
                              <h5 class="sub-title">Ready to get started?</h5>
                              <h3 class="title">Welcome Bonus €1,500 + 270 FS</h3>
                              <p>Already have an member ? <a href="javascript:void(0)" class="log-btn">Login</a></p>
                            </div>
                            <div class="input-area d-flex align-items-center">
                              <img src="assets/images/icon/email-icon.png" alt="icon">
                              <input type="text" placeholder="Email">
                            </div>
                            <div class="input-area d-flex align-items-center">
                              <img src="assets/images/icon/security.png" alt="icon">
                              <input type="text" placeholder="Password">
                            </div>
                            <div class="sidebar-single-item">
                              <label class="checkbox-single d-flex">
                                <span class="content-area">
                                  <span class="checkbox-area d-flex">
                                    <input type="checkbox" checked="checked">
                                    <span class="checkmark"></span>
                                  </span>
                                  <span class="item-title d-flex align-items-center">
                                    <span>I am at least 18 years of age and I have read,
                                      accepted and agreed to the <u><a href="javascript:void(0)">Privacy Policy</a>,
                                        Bonus T&C, <a href="javascript:void(0)">Terms and Conditions</a></u>
                                    </span>
                                  </span>
                                </span>
                              </label>
                              <label class="checkbox-single d-flex align-items-center">
                                <span class="content-area">
                                  <span class="checkbox-area d-flex">
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                  </span>
                                  <span class="item-title d-flex align-items-center">
                                    <span>I want to receive promotions by e-mail.</span>
                                  </span>
                                </span>
                              </label>
                              <div class="btn-area text-center">
                                <a href="javascript:void(0)" class="cmn-btn mt-4">Get Start Now
                                  <i class="icon-d-right-arrow-2"></i>
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Const from '@/const'

export default {
  name: 'LoginPopup',
  props: {
    games: Array
  },
  data() {
    return {
      Const,
    }
  }
}
</script>

<style scoped></style>