<template>
  <div id="app">
    <router-view /> <!-- This renders the active route component -->
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
/* Global styles for your app */
</style>
