import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createPinia } from 'pinia';
import '@/assets/css/bootstrap.min.css';
import '@/assets/css/fontawesome.min.css';
import '@/assets/css/jquery-ui.css';
import '@/assets/css/plugin/nice-select.css';
import '@/assets/css/plugin/magnific-popup.css';
import '@/assets/css/plugin/slick.css';
import '@/assets/css/ara-font.css';
import '@/assets/css/plugin/animate.css';
import '@/assets/css/style.css';
import 'vue3-carousel/dist/carousel.css'

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(pinia);
app.mount('#app')
