<template>
  <section class="total-jackpot">
    <div class="overlay pt-120 pb-120">
      <div class="container wow fadeInUp">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="section-area text-center">
              <h5 class="sub-title">Latest Winners</h5>
              <h3 class="title">122 163 738,00 EUR</h3>
              <div class="btn-area mt-40">
                <a href="games.html" class="cmn-btn">
                  All Jackpot Games
                  <i class="icon-d-right-arrow-2"></i>
                </a>
              </div>
              <div class="icon-img"><img src="assets/images/jackpot-icon.png" alt="icon"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="title mb-20 d-flex justify-content-between">
              <h4>Jackpot Games</h4>
              <a href="javascript:void(0)">Show All</a>
            </div>
            <div class="jackpot-carousel">
              <Carousel v-bind="config">
                <Slide v-for="game in games" :key="game.id" class="single">
                  <div class="single-slide">
                    <div class="box">
                      <div class="img-box">
                        <img :src="`${Const.URL}/frontend/Default/ico/${game.name}.jpg`" :alt="game.title" />
                      </div>
                      <div class="img-box">
                        <img :src="`${Const.URL}/frontend/Default/ico/${game.name}.jpg`" :alt="game.title" />
                      </div>
                    </div>
                  </div>
                </Slide>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Const from '@/const'
import { Carousel, Slide } from 'vue3-carousel'

export default {
  name: 'HomeJackpotGames',
  props: {
    games: Array
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      Const,
      config: {
        itemsToShow: 3,
        autoplay: 3000,
        gap: 10
      }
    }
  }
}
</script>

<style scoped></style>