<template>
    <main>
        <section class="banner-section inner-banner games">
            <div class="overlay">
                <div class="banner-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-10">
                                <div class="main-content">
                                    <h1>Games</h1>
                                    <div class="breadcrumb-area">
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb d-flex align-items-center">
                                                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Games</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Banner Section end -->

        <!-- Games section start -->
        <section class="games-section">
            <div class="overlay pb-120">
                <div class="top-nav">
                    <div class="container">
                        <div class="row justify-content-center wow fadeInUp">
                            <div class="col-lg-12">
                                <ul class="nav gap-2 justify-content-lg-between justify-content-start" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="slots-tab" data-bs-toggle="tab"
                                            data-bs-target="#slots" type="button" role="tab" aria-controls="slots"
                                            aria-selected="true">
                                            <img src="assets/images/icon/games-tab-icon-1.png" alt="Image">
                                            slots
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="table-tab" data-bs-toggle="tab"
                                            data-bs-target="#table" type="button" role="tab" aria-controls="table"
                                            aria-selected="false">
                                            <img src="assets/images/icon/games-tab-icon-2.png" alt="Image">
                                            table
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="live-tab" data-bs-toggle="tab"
                                            data-bs-target="#live" type="button" role="tab" aria-controls="live"
                                            aria-selected="false">
                                            <img src="assets/images/icon/games-tab-icon-3.png" alt="Image">
                                            live
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="jackpot-tab" data-bs-toggle="tab"
                                            data-bs-target="#jackpot" type="button" role="tab" aria-controls="jackpot"
                                            aria-selected="false">
                                            <img src="assets/images/icon/games-tab-icon-4.png" alt="Image">
                                            faq
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="tournament-tab" data-bs-toggle="tab"
                                            data-bs-target="#tournament" type="button" role="tab"
                                            aria-controls="tournament" aria-selected="false">
                                            <img src="assets/images/icon/games-tab-icon-5.png" alt="Image">
                                            tournament
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="promotions-tab" data-bs-toggle="tab"
                                            data-bs-target="#promotions" type="button" role="tab"
                                            aria-controls="promotions" aria-selected="false">
                                            <img src="assets/images/icon/games-tab-icon-6.png" alt="Image">
                                            promotions
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="instant-wins-tab" data-bs-toggle="tab"
                                            data-bs-target="#instant-wins" type="button" role="tab"
                                            aria-controls="instant-wins" aria-selected="false">
                                            <img src="assets/images/icon/games-tab-icon-7.png" alt="Image">
                                            faq
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="others-tab" data-bs-toggle="tab"
                                            data-bs-target="#others" type="button" role="tab" aria-controls="others"
                                            aria-selected="false">
                                            <img src="assets/images/icon/games-tab-icon-8.png" alt="Image">
                                            others
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container pt-120">
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="slots" role="tabpanel" aria-labelledby="slots-tab">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-xl-4 col-md-7">
                                    <ul class="nav bottom-nav gap-3" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="slots-all-tab" data-bs-toggle="tab"
                                                data-bs-target="#slots-all" type="button" role="tab"
                                                aria-controls="slots-all" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="slots-popular-tab" data-bs-toggle="tab"
                                                data-bs-target="#slots-popular" type="button" role="tab"
                                                aria-controls="slots-popular" aria-selected="false">Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="slots-new-tab" data-bs-toggle="tab"
                                                data-bs-target="#slots-new" type="button" role="tab"
                                                aria-controls="slots-new" aria-selected="false">New</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-5">
                                    <form action="#">
                                        <div class="form-group input-area d-flex align-items-center">
                                            <input type="text" placeholder="Search Game" autocomplete="off">
                                            <button class="btn-area"><img src="assets/images/icon/search-icon.png"
                                                    alt="icon"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="slots-all" role="tabpanel"
                                    aria-labelledby="slots-all-tab">
                                    <div class="all-items">
                                        <div class="single-item" v-for="game in allGames" :key="game.id">
                                            <a target="_blank" :href="`${Const.URL}/game/${game.name}`">
                                                <div
                                                    class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                    <div class="bg-area">
                                                        <img class="bg-item"
                                                            :src="`${Const.URL}/frontend/Default/ico/${game.name}.jpg`"
                                                            :alt="game.title">
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="slots-popular" role="tabpanel"
                                    aria-labelledby="slots-popular-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="slots-new" role="tabpanel"
                                    aria-labelledby="slots-new-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="table" role="tabpanel" aria-labelledby="table-tab">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-xl-4 col-md-7">
                                    <ul class="nav bottom-nav gap-3" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="table-all-tab" data-bs-toggle="tab"
                                                data-bs-target="#table-all" type="button" role="tab"
                                                aria-controls="table-all" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="table-popular-tab" data-bs-toggle="tab"
                                                data-bs-target="#table-popular" type="button" role="tab"
                                                aria-controls="table-popular" aria-selected="false">Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="table-new-tab" data-bs-toggle="tab"
                                                data-bs-target="#table-new" type="button" role="tab"
                                                aria-controls="table-new" aria-selected="false">New</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-5">
                                    <form action="#">
                                        <div class="form-group input-area d-flex align-items-center">
                                            <input type="text" placeholder="Search Game" autocomplete="off">
                                            <button class="btn-area"><img src="assets/images/icon/search-icon.png"
                                                    alt="icon"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="table-all" role="tabpanel"
                                    aria-labelledby="table-all-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="table-popular" role="tabpanel"
                                    aria-labelledby="table-popular-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="table-new" role="tabpanel"
                                    aria-labelledby="table-new-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="live" role="tabpanel" aria-labelledby="live-tab">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-xl-4 col-md-7">
                                    <ul class="nav bottom-nav gap-3" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="live-all-tab" data-bs-toggle="tab"
                                                data-bs-target="#live-all" type="button" role="tab"
                                                aria-controls="live-all" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="live-popular-tab" data-bs-toggle="tab"
                                                data-bs-target="#live-popular" type="button" role="tab"
                                                aria-controls="live-popular" aria-selected="false">Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="live-new-tab" data-bs-toggle="tab"
                                                data-bs-target="#live-new" type="button" role="tab"
                                                aria-controls="live-new" aria-selected="false">New</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-5">
                                    <form action="#">
                                        <div class="form-group input-area d-flex align-items-center">
                                            <input type="text" placeholder="Search Game" autocomplete="off">
                                            <button class="btn-area"><img src="assets/images/icon/search-icon.png"
                                                    alt="icon"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="live-all" role="tabpanel"
                                    aria-labelledby="live-all-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="live-popular" role="tabpanel"
                                    aria-labelledby="live-popular-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="live-new" role="tabpanel" aria-labelledby="live-new-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="jackpot" role="tabpanel" aria-labelledby="jackpot-tab">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-xl-4 col-md-7">
                                    <ul class="nav bottom-nav gap-3" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="jackpot-all-tab" data-bs-toggle="tab"
                                                data-bs-target="#jackpot-all" type="button" role="tab"
                                                aria-controls="jackpot-all" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="jackpot-popular-tab" data-bs-toggle="tab"
                                                data-bs-target="#jackpot-popular" type="button" role="tab"
                                                aria-controls="jackpot-popular" aria-selected="false">Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="jackpot-new-tab" data-bs-toggle="tab"
                                                data-bs-target="#jackpot-new" type="button" role="tab"
                                                aria-controls="jackpot-new" aria-selected="false">New</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-5">
                                    <form action="#">
                                        <div class="form-group input-area d-flex align-items-center">
                                            <input type="text" placeholder="Search Game" autocomplete="off">
                                            <button class="btn-area"><img src="assets/images/icon/search-icon.png"
                                                    alt="icon"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="jackpot-all" role="tabpanel"
                                    aria-labelledby="jackpot-all-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="jackpot-popular" role="tabpanel"
                                    aria-labelledby="jackpot-popular-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="jackpot-new" role="tabpanel"
                                    aria-labelledby="jackpot-new-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tournament" role="tabpanel" aria-labelledby="tournament-tab">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-xl-4 col-md-7">
                                    <ul class="nav bottom-nav gap-3" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="tournament-all-tab" data-bs-toggle="tab"
                                                data-bs-target="#tournament-all" type="button" role="tab"
                                                aria-controls="tournament-all" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="tournament-popular-tab" data-bs-toggle="tab"
                                                data-bs-target="#tournament-popular" type="button" role="tab"
                                                aria-controls="tournament-popular"
                                                aria-selected="false">Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="tournament-new-tab" data-bs-toggle="tab"
                                                data-bs-target="#tournament-new" type="button" role="tab"
                                                aria-controls="tournament-new" aria-selected="false">New</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-5">
                                    <form action="#">
                                        <div class="form-group input-area d-flex align-items-center">
                                            <input type="text" placeholder="Search Game" autocomplete="off">
                                            <button class="btn-area"><img src="assets/images/icon/search-icon.png"
                                                    alt="icon"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="tournament-all" role="tabpanel"
                                    aria-labelledby="tournament-all-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tournament-popular" role="tabpanel"
                                    aria-labelledby="tournament-popular-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tournament-new" role="tabpanel"
                                    aria-labelledby="tournament-new-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="promotions" role="tabpanel" aria-labelledby="promotions-tab">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-xl-4 col-md-7">
                                    <ul class="nav bottom-nav gap-3" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="promotions-all-tab" data-bs-toggle="tab"
                                                data-bs-target="#promotions-all" type="button" role="tab"
                                                aria-controls="promotions-all" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="promotions-popular-tab" data-bs-toggle="tab"
                                                data-bs-target="#promotions-popular" type="button" role="tab"
                                                aria-controls="promotions-popular"
                                                aria-selected="false">Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="promotions-new-tab" data-bs-toggle="tab"
                                                data-bs-target="#promotions-new" type="button" role="tab"
                                                aria-controls="promotions-new" aria-selected="false">New</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-5">
                                    <form action="#">
                                        <div class="form-group input-area d-flex align-items-center">
                                            <input type="text" placeholder="Search Game" autocomplete="off">
                                            <button class="btn-area"><img src="assets/images/icon/search-icon.png"
                                                    alt="icon"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="promotions-all" role="tabpanel"
                                    aria-labelledby="promotions-all-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="promotions-popular" role="tabpanel"
                                    aria-labelledby="promotions-popular-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="promotions-new" role="tabpanel"
                                    aria-labelledby="promotions-new-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="instant-wins" role="tabpanel" aria-labelledby="instant-wins-tab">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-xl-4 col-md-7">
                                    <ul class="nav bottom-nav gap-3" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="instant-wins-all-tab"
                                                data-bs-toggle="tab" data-bs-target="#instant-wins-all" type="button"
                                                role="tab" aria-controls="instant-wins-all"
                                                aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="instant-wins-popular-tab" data-bs-toggle="tab"
                                                data-bs-target="#instant-wins-popular" type="button" role="tab"
                                                aria-controls="instant-wins-popular"
                                                aria-selected="false">Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="instant-wins-new-tab" data-bs-toggle="tab"
                                                data-bs-target="#instant-wins-new" type="button" role="tab"
                                                aria-controls="instant-wins-new" aria-selected="false">New</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-5">
                                    <form action="#">
                                        <div class="form-group input-area d-flex align-items-center">
                                            <input type="text" placeholder="Search Game" autocomplete="off">
                                            <button class="btn-area"><img src="assets/images/icon/search-icon.png"
                                                    alt="icon"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="instant-wins-all" role="tabpanel"
                                    aria-labelledby="instant-wins-all-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="instant-wins-popular" role="tabpanel"
                                    aria-labelledby="instant-wins-popular-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="instant-wins-new" role="tabpanel"
                                    aria-labelledby="instant-wins-new-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="others" role="tabpanel" aria-labelledby="others-tab">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-xl-4 col-md-7">
                                    <ul class="nav bottom-nav gap-3" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="others-all-tab" data-bs-toggle="tab"
                                                data-bs-target="#others-all" type="button" role="tab"
                                                aria-controls="others-all" aria-selected="true">All</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="others-popular-tab" data-bs-toggle="tab"
                                                data-bs-target="#others-popular" type="button" role="tab"
                                                aria-controls="others-popular" aria-selected="false">Popular</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="others-new-tab" data-bs-toggle="tab"
                                                data-bs-target="#others-new" type="button" role="tab"
                                                aria-controls="others-new" aria-selected="false">New</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-5">
                                    <form action="#">
                                        <div class="form-group input-area d-flex align-items-center">
                                            <input type="text" placeholder="Search Game" autocomplete="off">
                                            <button class="btn-area"><img src="assets/images/icon/search-icon.png"
                                                    alt="icon"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="others-all" role="tabpanel"
                                    aria-labelledby="others-all-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="others-popular" role="tabpanel"
                                    aria-labelledby="others-popular-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-1.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-2.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-3.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-4.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-6.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-7.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="others-new" role="tabpanel"
                                    aria-labelledby="others-new-tab">
                                    <div class="all-items">
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-16.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-17.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-18.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-5.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-8.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-9.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-10.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-11.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-12.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-13.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-14.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="single-item">
                                            <div
                                                class="magnific-area position-relative d-flex align-items-center justify-content-around">
                                                <div class="bg-area">
                                                    <img class="bg-item" src="assets/images/games-logo-15.png"
                                                        alt="image">
                                                </div>
                                                <a class="mfp-iframe popupvideo position-absolute d-flex align-items-center justify-content-center"
                                                    href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-area mt-3 text-center">
                                        <a href="javascript:void(0)" class="cmn-btn">
                                            Load More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Games section end -->

        <!-- Drops & Wins start -->
        <section class="drops-wins">
            <div class="overlay pt-120 pb-120">
                <div class="container wow fadeInUp">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-xxl-5 col-xl-6 col-md-6">
                            <div class="section-header">
                                <h5 class="sub-title">Drops & Wins ( Live Casino)</h5>
                                <h2 class="title">Easy way for crypto Play</h2>
                            </div>
                            <h2 class="currency">€500.000</h2>
                            <div class="countdown d-flex align-items-center">
                                <span>Finishes in:</span>
                                <h6>
                                    <span class="days">00</span><span class="ref">Days:</span>
                                </h6>
                                <h6>
                                    <span class="hours">00</span><span class="ref">Hrs:</span>
                                </h6>
                                <h6>
                                    <span class="minutes">00</span><span class="ref">Mins</span>
                                </h6>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-5 col-md-6">
                            <div class="right-box">
                                <h4 class="mb-30">Deposit and Play</h4>
                                <form action="#">
                                    <div class="input-field thumb-select d-flex">
                                        <input type="text" placeholder="0.001">
                                        <select>
                                            <option value="1">BTC</option>
                                            <option value="2">ETH</option>
                                            <option value="3">LTC</option>
                                        </select>
                                    </div>
                                    <div class="btn-area mb-30 mt-60">
                                        <a href="javascript:void(0)" class="cmn-btn w-100">
                                            Start Playing
                                            <i class="icon-d-right-arrow-2"></i>
                                        </a>
                                    </div>
                                    <p>By Clicking you agree with our</p>
                                    <div class="link-area gap-1 d-flex align-items-center justify-content-center">
                                        <a href="terms-conditions.html">Terms and Conditions,</a>
                                        <a href="privacy-policy.html">Privacy Policy</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Drops & Wins end -->

        <!-- Latest Bets start -->
        <section class="latest-bets">
            <div class="overlay pt-120 pb-120">
                <div class="container wow fadeInUp">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-header text-center">
                                <h5 class="sub-title">Latest Winners</h5>
                                <h2 class="title">Latest Bets</h2>
                                <p>More and more winners are added every time! To locate the most recent winner's
                                    information</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <div class="d-flex justify-content-center">
                                <ul class="nav" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="all-bets-tab" data-bs-toggle="tab"
                                            data-bs-target="#all-bets" type="button" role="tab" aria-controls="all-bets"
                                            aria-selected="true">All Bets</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="high-rollers-tab" data-bs-toggle="tab"
                                            data-bs-target="#high-rollers" type="button" role="tab"
                                            aria-controls="high-rollers" aria-selected="false">High Rollers</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="wager-contest-tab" data-bs-toggle="tab"
                                            data-bs-target="#wager-contest" type="button" role="tab"
                                            aria-controls="wager-contest" aria-selected="false">Wager Contest</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content mt-40">
                                <div class="tab-pane fade show active" id="all-bets" role="tabpanel"
                                    aria-labelledby="all-bets-tab">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">User</th>
                                                    <th scope="col">Bet Id</th>
                                                    <th scope="col">Bet Amount</th>
                                                    <th scope="col">Multiplier</th>
                                                    <th scope="col">Game</th>
                                                    <th scope="col">Profit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-1.png" alt="Image">
                                                            <span>Tom Bass</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-2.png" alt="Image">
                                                            <span>Karl Day </span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Poker</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-3.png" alt="Image">
                                                            <span>Jon Arnold</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span class="loss">0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-4.png" alt="Image">
                                                            <span>Kim Clark</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Slot Machine</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-5.png" alt="Image">
                                                            <span>Sergio Roy</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/trx.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/trx.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-6.png" alt="Image">
                                                            <span>Wm Dixon</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Bitclimber</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-7.png" alt="Image">
                                                            <span>Leo Berry</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span class="loss">0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-8.png" alt="Image">
                                                            <span>Ross Ryan</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-9.png" alt="Image">
                                                            <span>Alton Ray</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Poker</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="high-rollers" role="tabpanel"
                                    aria-labelledby="high-rollers-tab">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">User</th>
                                                    <th scope="col">Bet Id</th>
                                                    <th scope="col">Bet Amount</th>
                                                    <th scope="col">Multiplier</th>
                                                    <th scope="col">Game</th>
                                                    <th scope="col">Profit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-4.png" alt="Image">
                                                            <span>Kim Clark</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Slot Machine</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-5.png" alt="Image">
                                                            <span>Sergio Roy</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/trx.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/trx.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-6.png" alt="Image">
                                                            <span>Wm Dixon</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Bitclimber</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-1.png" alt="Image">
                                                            <span>Tom Bass</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-2.png" alt="Image">
                                                            <span>Karl Day </span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Poker</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-3.png" alt="Image">
                                                            <span>Jon Arnold</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span class="loss">0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-7.png" alt="Image">
                                                            <span>Leo Berry</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span class="loss">0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-8.png" alt="Image">
                                                            <span>Ross Ryan</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-9.png" alt="Image">
                                                            <span>Alton Ray</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Poker</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="wager-contest" role="tabpanel"
                                    aria-labelledby="wager-contest-tab">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">User</th>
                                                    <th scope="col">Bet Id</th>
                                                    <th scope="col">Bet Amount</th>
                                                    <th scope="col">Multiplier</th>
                                                    <th scope="col">Game</th>
                                                    <th scope="col">Profit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-6.png" alt="Image">
                                                            <span>Wm Dixon</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Bitclimber</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-7.png" alt="Image">
                                                            <span>Leo Berry</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span class="loss">0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-8.png" alt="Image">
                                                            <span>Ross Ryan</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-9.png" alt="Image">
                                                            <span>Alton Ray</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Poker</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-1.png" alt="Image">
                                                            <span>Tom Bass</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-2.png" alt="Image">
                                                            <span>Karl Day </span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Poker</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/dash.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-3.png" alt="Image">
                                                            <span>Jon Arnold</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/etc.png" alt="Image">
                                                            <span class="loss">0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-4.png" alt="Image">
                                                            <span>Kim Clark</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Slot Machine</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/btc-icon-2.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <div class="icon-box">
                                                            <img src="assets/images/table-image-5.png" alt="Image">
                                                            <span>Sergio Roy</span>
                                                        </div>
                                                    </th>
                                                    <td>b799b8724b</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/trx.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                    <td>3.00X</td>
                                                    <td class="game">Dice</td>
                                                    <td>
                                                        <div class="icon-box">
                                                            <img src="assets/images/icon/trx.png" alt="Image">
                                                            <span>0.00000051</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Latest Bets end -->
    </main>
</template>

<script>
import { allGames } from '@/sampleData';
import Const from '@/const'

export default {
    name: 'GamesPage',
    components: {},
    data() {
        return {
            Const,
            allGames: allGames
        };
    },
};
</script>

<style scoped></style>