<template>
  <section class="popular-game">
    <div class="overlay pt-120 pb-120">
      <div class="abs-item">
        <img src="assets/images/popular-game-item.png" alt="icon">
      </div>
      <div class="container">
        <div class="row justify-content-center wow fadeInUp">
          <div class="col-lg-6 text-center">
            <div class="section-text">
              <h5 class="sub-title">Easy way for crypto Play</h5>
              <h2 class="title">Popular Game</h2>
              <p>Unparalleled slots to give lovers of the gambling world an exciting gaming experience par
                excellence</p>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="games-carousel">
            <Carousel v-bind="config">
              <Slide v-for="game in games" :key="game.id" class="single">
                <div class="single-box">
                  <a target="_blank" :href="`${Const.URL}/game/${game.name}`">
                    <img :src="`${Const.URL}/frontend/Default/ico/${game.name}.jpg`" :alt="game.title" />
                  </a>
                  <p>{{ game.title }}</p>
                </div>
              </Slide>

              <!-- <div v-for="game in games" :key="game.id" class="single">
              <div class="single-box">
                <a :href="`${Const.URL}/game/${game.name}`">
                  <img :src="`${Const.URL}/frontend/Default/ico/${game.name}.jpg`" :alt="game.title" />
                </a>
                <p>{{ game.title }}</p>
              </div>
            </div> -->

            </Carousel>

          </div>
          <div class="col-lg-12">
            <div class="btn-area mt-40 text-center">
              <router-link to="/games" class="cmn-btn">
                All Games
                <i class="icon-d-right-arrow-2"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Const from '@/const'
import { Carousel, Slide } from 'vue3-carousel'

export default {
  name: 'HomePopularGames',
  props: {
    games: Array
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      Const,
      config: {
        itemsToShow: 6,
        autoplay: 3000,
        gap: 10
      }
    }
  }
}
</script>

<style scoped></style>