<template>
  <header class="header-section">
    <div class="overlay">
      <div class="container">
        <div class="row d-flex header-area">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="index.html">
              <img src="assets/images/fav.png" class="fav d-none d-lg-block d-xl-none" alt="fav">
              <img src="assets/images/logo.png" class="logo d-block d-lg-none d-xl-block" alt="logo">
            </a>
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbar-content">
              <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse justify-content-between" id="navbar-content">
              <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link to="/" class="nav-link active">Home</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/games" class="nav-link">Games</router-link>
                </li>
                <li class="nav-item dropdown main-navbar">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside">Lottery</a>
                  <ul class="dropdown-menu main-menu shadow">
                    <li><a class="nav-link" href="lottery.html">Lottery</a></li>
                    <li><a class="nav-link" href="lottery-details.html">Lottery Details</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown main-navbar">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside">Pages</a>
                  <ul class="dropdown-menu main-menu shadow">
                    <li><router-link to="/about" class="nav-link" href="about-us.html">About Us</router-link></li>
                    <li class="dropend sub-navbar">
                      <a href="javascript:void(0)" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown"
                        data-bs-auto-close="outside">Promotions</a>
                      <ul class="dropdown-menu sub-menu shadow">
                        <li><a class="nav-link" href="promotions.html">Promotions</a></li>
                        <li><a class="nav-link" href="promotions-details.html">Details</a></li>
                      </ul>
                    </li>
                    <li class="dropend sub-navbar">
                      <a href="javascript:void(0)" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown"
                        data-bs-auto-close="outside">Tournaments</a>
                      <ul class="dropdown-menu sub-menu shadow">
                        <li><a class="nav-link" href="tournaments.html">Tournaments</a></li>
                        <li><a class="nav-link" href="tournaments-result.html">Result</a></li>
                        <li><a class="nav-link" href="tournaments-details.html">Details</a></li>
                      </ul>
                    </li>
                    <li><a class="nav-link" href="terms-conditions.html">Terms Conditions</a></li>
                    <li><a class="nav-link" href="privacy-policy.html">Privacy Policy</a></li>
                    <li><a class="nav-link" href="error.html">Error</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown main-navbar">
                  <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside">Blog</a>
                  <ul class="dropdown-menu main-menu shadow">
                    <li><a class="nav-link" href="blog.html">Blog</a></li>
                    <li><a class="nav-link" href="blog-2.html">Blog Second</a></li>
                    <li><a class="nav-link" href="blog-details.html">Blog Details</a></li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="contact.html">Contact</a>
                </li>
              </ul>
              <div class="right-area header-action d-flex align-items-center max-un">
                <button type="button" class="login" data-bs-toggle="modal" data-bs-target="#loginMod">
                  Sign In
                </button>
                <button type="button" class="cmn-btn reg" data-bs-toggle="modal" data-bs-target="#loginMod">
                  Sign Up
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { usePopupStore } from '../stores/popup';

export default {
  name: 'HeaderComponent',
  props: {
    msg: String
  },
  methods: {
        openLoginPopup() {
            const popupStore = usePopupStore();
            popupStore.showPopup('login');
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-brand {
  img {
    max-height: 80px;
  }
}
</style>
