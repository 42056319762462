<template>
  <!-- Footer Area Start -->
  <footer class="footer-section">
        <div class="container">
            <!-- <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="footer-form">
                        <div class="section-area mb-30 text-center">
                            <h4 class="title">To Get Exclusive Benefits</h4>
                        </div>
                        <form action="#">
                            <div class="form-group input-area d-flex align-items-center">
                                <input type="text" placeholder="Enter your email address">
                                <button class="btn-area"><img src="assets/images/icon/send-icon.png" alt="icon"></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->
            <div class="footer-area pt-120">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="footer-top d-flex align-items-center justify-content-between">
                            <router-link to="/">
                                <img src="assets/images/logo.png" class="logo" alt="logo">
                            </router-link>
                            <div class="footer-box">
                                <ul class="footer-link d-flex align-items-center gap-4">
                                     <li><router-link to="/about-us">About Us</router-link></li>
                                     <li><router-link to="/contact">Contact</router-link></li>
                                     <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                     <li><router-link to="/terms-conditions">Terms of Service</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                            <div class="copyright text-center">
                                <p>© Azurecoinz 2023. All rights reserved.</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                            <div class="social">
                                <ul class="footer-link gap-2 d-flex align-items-center">
                                     <li><router-link to="/javascript:void(0)"><i class="fb fab fa-facebook-f"></i></router-link></li>
                                     <li><router-link to="/javascript:void(0)"><i class="ins fab fa-instagram"></i></router-link></li>
                                     <li><router-link to="/javascript:void(0)"><i class="tw fab fa-twitter"></i></router-link></li>
                                     <li><router-link to="/javascript:void(0)"><i class="in fab fa-linkedin-in"></i></router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Area End -->
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .footer-top {
    .logo {
      max-height: 80px;
    }
  }
</style>
